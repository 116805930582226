<template>
  <div>
    <categories-desktop-menu :visible="open" @hidden="triggerHide"></categories-desktop-menu>
  </div>
</template>

<script>
import CategoriesDesktopMenu from "@/views/pricex/elements/CategoriesMenu/CategoriesDesktopMenu";

export default {
  name: "CategoriesMenu",
  components: {CategoriesDesktopMenu},
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    triggerHide() {
      this.$emit('hidden')
    }
  }
}
</script>