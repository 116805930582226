<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
            class="navbar-brand"
            to="/"
        >
          <span class="brand-logo">
            <logo/>
          </span>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {BLink} from 'bootstrap-vue'
import {$themeConfig} from '@themeConfig'
import Logo from '@/views/pricex/elements/Logo.vue'

export default {
  components: {
    Logo,
    BLink,
  },
  setup() {
    // App Name
    const {appName, appLogoImage} = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  }
}
</script>
