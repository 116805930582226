<template>
  <div class="navbar-container d-flex content align-items-center pl-0 pt-0 pb-0">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none"  v-if="['xs', 'sm', 'md'].indexOf(breakpoint) > -1">
      <li class="nav-item">
        <b-button
            variant="primary"
            class="btn-categories-menu d-flex"
            @click="toggleVerticalMenuActive"
        >
          <div>
            <span/>
            <span/>
            <span/>
          </div>
        </b-button>
      </li>
      <li class="nav-item d-flex align-items-center">
        <mobile-search></mobile-search>
      </li>
    </ul>
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex" v-if="['lg', 'xl'].indexOf(breakpoint) > -1">
      <header-categories-button></header-categories-button>
      <ul class="nav navbar-nav w-50 mr-auto ml-auto pl-lg-5">
        <li class="nav-item w-100">
          <search-input></search-input>
        </li>
      </ul>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale/>
      <dark-Toggler/>
      <!--      <cart-dropdown/>-->
<!--      <notification-dropdown/>-->
      <guest-dropdown v-if="!loggedIn"/>
      <user-dropdown v-else/>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BButton, BNavbarNav
} from 'bootstrap-vue'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
// import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import HeaderCategoriesButton from "@/views/pricex/elements/CategoriesMenu/HeaderCategoriesButton";
import GuestDropdown from "@core/layouts/components/app-navbar/components/GuestDropdown";
import {EventBus} from "@/plugins/event-bus";
import SearchInput from "@core/layouts/components/app-navbar/SearchInput";
import MobileSearch from "@/views/pricex/elements/Search/MobileSearch";

export default {
  components: {
    MobileSearch,
    SearchInput,
    GuestDropdown,
    HeaderCategoriesButton,
    BButton,
    // Navbar Components
    BNavbarNav,
    Locale,
    DarkToggler,
    // CartDropdown,
    // NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      loggedIn: false
    }
  },
  mounted() {
    let userData = localStorage.getItem('userData')
    this.loggedIn = userData !== null;
    EventBus.$on('logout', () => {
      this.loggedIn = false
    })
    EventBus.$on('login', () => {
      this.loggedIn = true
    })
  },
  computed: {
    breakpoint() {
      return this.$store.getters["app/currentBreakPoint"]
    },
  }
}
</script>
