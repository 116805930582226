<template>
  <b-collapse :visible="visible" class="position-absolute w-100 border-bottom"
              :class="{'bg-light': !isDarkTheme, 'bg-dark-theme': isDarkTheme}"
  >
    <b-row>
      <b-col md="4" lg="3" xl="2">
        <b-list-group class="rounded-0 h-100 pt-5 pb-5" flush
                      :class="{'bg-white': !isDarkTheme, 'bg-dark-theme': isDarkTheme}"
        >
          <b-list-group-item button v-for="(mainCategory, index) in categories" :key="mainCategory.key"
                             :class="{'active': index === selectedCategoryIndex, 'dark-theme': isDarkTheme}"
                             class="align-items-center d-flex icon"
                             :data-icon="mainCategory.key"
                             @click="selectedCategoryIndex = index">
            {{ mainCategory.label }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col lg="9" xl="10" class="p-1 pb-3">
        <b-row>
          <b-col>
            <b-list-group horizontal>
<!--              <b-list-group-item class="border-0 bg-transparent">-->
<!--                <router-link :to="{name: 'blog'}" custom v-slot="{ navigate }">-->
<!--                  <span @click="navigate" @keypress.enter="navigate" role="link"-->
<!--                        class="cursor-pointer">{{ $t('buttons.useful') }}</span>-->
<!--                </router-link>-->
<!--              </b-list-group-item>-->
              <b-list-group-item class="border-0 bg-transparent">
                <router-link :to="{name: 'prices'}" custom v-slot="{ navigate }">
                  <span @click="navigate" @keypress.enter="navigate" role="link"
                        class="cursor-pointer">{{ $t('buttons.prices') }}</span>
                </router-link>
              </b-list-group-item>
              <b-list-group-item class="border-0 bg-transparent">
                <router-link :to="{name: 'terms', params: {page: 'terms-and-conditions'}}" custom v-slot="{ navigate }">
                  <span @click="navigate" @keypress.enter="navigate" role="link"
                        class="cursor-pointer">{{ $t('buttons.terms_and_conditions') }}</span>
                </router-link>
              </b-list-group-item>
              <!--              <b-list-group-item class="border-0 bg-transparent">{{ $t('buttons.advertising') }}</b-list-group-item>-->
              <!--              <b-list-group-item class="border-0 bg-transparent">{{ $t('buttons.for_business_clients') }}</b-list-group-item>-->
              <b-list-group-item class="border-0 bg-transparent">
                <router-link :to="{name: 'contacts'}" custom v-slot="{ navigate }">
                  <span @click="navigate" @keypress.enter="navigate" role="link"
                        class="cursor-pointer">{{ $t('buttons.contacts') }}</span>
                </router-link>
              </b-list-group-item>
            </b-list-group>
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" xl="9">
            <div class="d-flex flex-row flex-wrap">
              <b-col lg="3" md="4"
                     v-for="childCategory in _.reverse(_.sortBy(selectedCategory.children, o => o.children.length))"
                     :key="childCategory.key">
                <b-list-group flush>
                  <b-list-group-item :to="{name: 'category', params: {'category': childCategory.key}}"
                                     class="border-0 bg-transparent font-weight-bold">{{ childCategory.label }}
                  </b-list-group-item>
                  <b-list-group-item class="border-0 bg-transparent py-0"
                                     :to="{name: 'category', params: {'category': subChild.key}}"
                                     v-for="subChild in _.slice(childCategory.children, 0, 6)" :key="subChild.key"
                  >
                    {{ subChild.label }}
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </div>
          </b-col>
          <b-col xl="3" class="d-none d-xl-block">
            <ad-banner :width="300" :height="250" :key="adContainerIndex"></ad-banner>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-collapse>
</template>

<script>
import {BCollapse, BListGroup, BListGroupItem, BRow, BCol} from "bootstrap-vue";
import _ from "lodash"
import AdBanner from "@/views/pricex/elements/AdBanner";

export default {
  components: {
    AdBanner,
    BCollapse, BListGroup, BListGroupItem, BRow, BCol,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  name: "CategoriesDesktopMenu",
  data() {
    return {
      selectedCategoryIndex: 0,
      adContainerIndex: 0
    }
  },
  computed: {
    categories() {
      return this.$store.state.categories.tree;
    },
    selectedCategory() {
      return _.get(this.$store.state.categories.tree, this.selectedCategoryIndex, {})
    },
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === 'dark'
    }
  },
  watch: {
    $route() {
      this.$emit('hidden')
    },
    selectedCategoryIndex() {
      this.adContainerIndex++
    }
  }
}
</script>
<style scoped lang="scss">
a.list-group-item {
  &:hover {
    text-decoration: underline;
  }
  padding: 0.75rem 0;
  font-size: 90%
}

.list-group-item {
  &:hover {
    text-decoration: underline;
  }

  .category-icon {
    width: 15px;
    margin-right: 10px;
  }
}

</style>
