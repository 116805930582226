<template>
  <div>
    <div
        class="btn btn-primary btn-categories-menu d-flex"
        :class="{'open': menuOpen}"
        @click="menuOpen = !menuOpen"
    >
      <div>
        <span/>
        <span/>
        <span/>
      </div>
      <div class="d-none d-md-flex text-start align-items-center">
        <span>{{ $t('header.all_categories') }}</span>
      </div>
    </div>
    <categories-menu :open="menuOpen" @hidden="menuOpen = false"></categories-menu>
  </div>
</template>

<script>
import CategoriesMenu from "@/views/pricex/elements/CategoriesMenu/CategoriesMenu";

export default {
  name: "HeaderCategoriesButton",
  components: {CategoriesMenu},
  data() {
    return {
      menuOpen: false,
    }
  }
}
</script>
<style scoped>
  .btn-categories-menu > div:nth-child(2) > span {
    white-space: pre;
  }
</style>