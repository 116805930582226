<template>
  <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name }}
        </p>
      </div>
      <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
      >
        <feather-icon
            v-if="!userData.name"
            icon="UserIcon"
            size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
        :to="{ name: 'user.account', params: {section: 'dashboard'}}"
        link-class="d-flex align-items-center"
    >
      <feather-icon
          size="16"
          icon="TrendingUpIcon"
          class="mr-50"
      />
      <span>{{ $t('buttons.dashboard') }}</span>
    </b-dropdown-item>
    <b-dropdown-item
        :to="{name: 'user.account', params: {section: 'profile'}}"
    >
      <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
      />
      <span>{{ $t('buttons.profile') }}</span>
    </b-dropdown-item>
    <b-dropdown-item
        :to="{name: 'user.account', params: {section: 'favorites'}}"
    >
      <feather-icon
          size="16"
          icon="HeartIcon"
          class="mr-50"
      />
      <span>{{ $t('buttons.favorites') }}</span>
    </b-dropdown-item>
    <b-dropdown-item
        :to="{name: 'user.account', params: {section: 'automations'}}"
    >
      <feather-icon
          size="16"
          icon="CommandIcon"
          class="mr-50"
      />
      <span>{{ $t('buttons.automations') }}</span>
    </b-dropdown-item>
    <b-dropdown-item
        :to="{name: 'user.account', params: {section: 'request'}}"
    >
      <feather-icon
          size="16"
          icon="GlobeIcon"
          class="mr-50"
      />
      <span>{{ $t('buttons.website_requests') }}</span>
    </b-dropdown-item>
    <b-dropdown-item
        :to="{name: 'user.account', params: {section: 'api-access'}}"
    >
      <feather-icon
          size="16"
          icon="ZapIcon"
          class="mr-50"
      />
      <span>{{ $t('buttons.api_access') }}</span>
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
    >
      <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
      />
      <span>{{ $t('buttons.logout') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import {avatarText} from '@core/utils/filter'
import {initialAbility} from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

import {EventBus} from "@/plugins/event-bus";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      avatarText,
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.data
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      this.$http.post('/logout').then(() => {
        EventBus.$emit('logout')
      });

      // Redirect to login page
      this.$router.push({name: 'auth-login'})
    },
  },
}
</script>
