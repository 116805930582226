<template>
  <img :src="logoPath" :alt="appName" class="img-fluid">
</template>

<script>
import {$themeConfig} from '@themeConfig'
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  name: 'Logo',
  setup() {
    // App Name
    const {appName, appLogoImage, appLogoImageDark} = $themeConfig.app
    const {skin} = useAppConfig()
    return {
      appName,
      appLogoImage,
      appLogoImageDark,
      skin
    }
  },
  computed: {
    logoPath() {
      return this.skin === 'dark' ? this.appLogoImageDark : this.appLogoImage
    }
  }
}
</script>