<template>
  <div>
    <b-button variant="none" @click="open = true" class="mobile-search-button"
              :class="{'has-query': !_.isEmpty(query)}">
      <feather-icon icon="SearchIcon" size="22" :class="{'text-light': skin === 'dark'}"></feather-icon>
    </b-button>

    <b-card no-body class="mobile-search" no-shadow :class="{'open': open}">
      <b-input-group>
        <b-form-input :placeholder="$t('questions.what_are_you_searching')"
                      v-model="query"
                      list="searchSuggest"
                      type="search"
                      @keydown.native.enter="search"
                      ref="searchInput"></b-form-input>
        <b-input-group-append>
          <b-button variant="none" @click="search">
            <feather-icon icon="SearchIcon" size="16" :class="{'text-light': skin === 'dark'}"></feather-icon>
          </b-button>
        </b-input-group-append>
      </b-input-group>

      <b-list-group flush>
        <b-list-group-item class="font-weight-bold">{{ $t('titles.popular_searches') }}</b-list-group-item>
        <b-list-group-item v-for="(word, index) in popularSearches" @click="search(word)" :key="index">{{ word }}
        </b-list-group-item>
        <b-list-group-item @click="open = false" class="text-center font-weight-bold">
          <feather-icon icon="ArrowLeftIcon"></feather-icon>
          {{ $t('buttons.back_to_pricex') }}
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BListGroup,
  BListGroupItem
} from "bootstrap-vue";
import _ from "lodash"

export default {
  name: "MobileSearch",
  components: {
    BButton,
    BCard,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BListGroup,
    BListGroupItem
  },
  data() {
    return {
      open: false,
      query: null
    }
  },
  methods: {
    search(query) {
      if (_.isString(query)) {
        this.query = query
      }
      this.open = false
    },
  },
  computed: {
    skin() {
      return this.$store.state.appConfig.layout.skin
    },
    popularSearches() {
      return [
        'puma', 'iphone 13', 'шкаф за баня', 'playstation 5', 'футболни обувки', 'tefal', 'apple watch'
      ];
    }
  },
  watch: {
    open(val) {
      if (val) {
        this.query = _.get(this.$route, 'query.q', null)
        this.$refs['searchInput'].focus()
      } else {
        if (_.isEmpty(this.query)) {
          this.$router.push({name: 'search'})
        } else {
          this.$router.push({name: 'search', query: {q: this.query}})
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.mobile-search {
  position: fixed;
  top: 0;
  bottom: auto;
  left: 0;
  width: 100%;
  border-radius: 0;
  z-index: 10;
  overflow: hidden;
  height: 0;

  &.open {
    height: 100%;
  }
}

.mobile-search-button {
  position: relative;

  &.has-query:after {
    content: '·';
    position: absolute;
    right: 10px;
    top: -10px;
    font-size: 2.5rem;
    color: #d62612;
  }
}
</style>