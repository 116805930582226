<template>
  <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
  >
    <template #button-content>
      <b-avatar
          size="40"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="danger"
      >
        <feather-icon
            icon="UserIcon"
            size="22"
        />
      </b-avatar>
    </template>
    <b-dropdown-item
        :to="{ name: 'auth.login'}"
        link-class="d-flex align-items-center"
    >
      <feather-icon
          size="16"
          icon="LogInIcon"
          class="mr-50"
      />
      <span>{{ $t('buttons.login') }}</span>
    </b-dropdown-item>
    <b-dropdown-item
        :to="{ name: 'auth.register'}"
        link-class="d-flex align-items-center"
    >
      <feather-icon
          size="16"
          icon="UserPlusIcon"
          class="mr-50"
      />
      <span>{{ $t('buttons.register') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {BNavItemDropdown, BDropdownItem, BAvatar} from "bootstrap-vue";
export default {
  components:{
    BNavItemDropdown,
    BDropdownItem,
    BAvatar
  },
  name: "GuestDropdown"
}
</script>