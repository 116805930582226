<template>
  <div>
    <b-list-group>
      <b-list-group-item class="d-flex justify-content-start align-items-center border-0 icon"
                         :class="{'dark-theme': isDarkTheme}"
                         v-for="(mainCategory, index) in categories" :key="mainCategory.key"
                         :data-icon="mainCategory.key"
                         @click="selectedCategoryIndex = index">{{ mainCategory.label }}
        <feather-icon icon="ChevronRightIcon"></feather-icon>
      </b-list-group-item>
    </b-list-group>
    <b-list-group class="text-center">
      <b-list-group-item class="border-0">
        <b-button variant="primary" class="progressier-install-button" data-behavior="disable" data-icons="false" :data-install="$t('buttons.install_application')" :data-installed="$t('buttons.already_installed')" ></b-button>
      </b-list-group-item>
    </b-list-group>
    <subcategories-menu :open="selectedCategoryIndex !== null"
                        :category="selectedCategory"
                        @hidden="selectedCategoryIndex = null"
    ></subcategories-menu>
  </div>
</template>

<script>
import { BListGroup, BListGroupItem, BButton} from "bootstrap-vue";
import SubcategoriesMenu from "@/views/pricex/elements/CategoriesMenu/SubcategoriesMenu";
import _ from "lodash"

export default {
  name: "CategoriesMobileMenu",
  components: {
    SubcategoriesMenu,
     BListGroup, BListGroupItem, BButton
  },
  data() {
    return {
      selectedCategoryIndex: null
    }
  },
  computed: {
    categories() {
      return this.$store.state.categories.tree;
    },
    selectedCategory() {
      return _.get(this.$store.state.categories.tree, this.selectedCategoryIndex, {})
    },
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === 'dark'
    }
  }
}
</script>
