<template>
  <b-input-group>
    <template #prepend>
      <div class="d-flex align-items-center px-1 border-bottom">
        <feather-icon icon="SearchIcon" size="18"></feather-icon>
      </div>
    </template>
    <b-form-input :placeholder="$t('questions.what_are_you_searching')"
                  type="search"
                  v-model="query"
                  @keydown.native.enter="search"
                  class="border-left-0 border-right-0 border-top-0 bg-transparent"></b-form-input>
    <template #append>
      <b-button variant="none" class="border-bottom" @click="search">{{ $t('buttons.search') }}</b-button>
    </template>
  </b-input-group>
</template>

<script>
import {BInputGroup, BFormInput, BButton} from "bootstrap-vue";

export default {
  components: {
    BInputGroup, BFormInput, BButton
  },
  name: "SearchInput",
  data(){
    return {
      query: null
    }
  },
  methods: {
    search(){
      this.$router.push({name: 'search', query: {q: this.query}})
    }
  }
}
</script>

<style scoped lang="scss">
.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none;
}

.form-control {
  &:focus {
    border-color: #e3e4e5!important;
  }
}
</style>