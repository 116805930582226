<template>
  <b-sidebar :visible="open" v-bind="$attrs" v-on="$listeners">
    <b-list-group role="tablist">
      <b-list-group-item class="d-flex justify-content-start align-items-center border-0" @click="$emit('hidden')"
      >
        <feather-icon icon="ChevronLeftIcon"></feather-icon>
        {{ category.label }}
      </b-list-group-item>

      <b-list-group-item v-b-toggle="'accordion-' + index" class="p-0"
                         v-for="(child, index) in category.children" :key="child.key"
      >
        <div class="d-flex justify-content-between p-1" v-if="!child.children.length">
          <router-link :to="{name: 'category', params: {'category': child.key}}">{{ child.label }}</router-link>
        </div>
        <div v-if="child.children.length">
          <div class="d-flex justify-content-between p-1">{{ child.label }}
            <feather-icon icon="ChevronDownIcon"></feather-icon>
          </div>
          <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
            <b-list-group class="p-0">
              <b-list-group-item class="d-flex justify-content-start align-items-center border-0"
                                 :to="{name: 'category', params: {'category': subChild.key}}"
                                 v-for="subChild in child.children" :key="subChild.key"
              >{{ subChild.label }}
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-sidebar>
</template>

<script>
import {BSidebar, BListGroup, BListGroupItem, BCollapse, VBToggle} from "bootstrap-vue";

export default {
  name: "SubcategoriesMenu",
  components: {
    BSidebar, BListGroup, BListGroupItem, BCollapse
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    category: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>
